import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export function exceptionHandling(exceptionData){
    // const navigate = useNavigate();
    if (exceptionData?.response?.status === 401) {
        swal("Error", "Your session has expired. Please login again.", "error").then(() => {
            // sessionExpired(router)
            localStorage.clear();
            // router.replace("/");
            window.location.href="/"
            // navigate("/login");
        });
        return
    }else
    if (exceptionData?.response?.status === 403) {
        swal("Error", exceptionData.response.data.message, "error").then(() => {
            // sessionExpired(router)
            localStorage.clear();
            // router.replace("/");
            window.location.href="/";
            // navigate("/login");
        });
        return
    }else
    if (exceptionData?.response?.status === 400) {
        swal("Error", exceptionData.response.data.message, "error");
        return
    } else {
        swal("Error", `It appears that either your internet connection or the server is not responding.`, "error");
        return
    }
};

export function formatBalance(balance, toFixedNumber = 2) {
    // Convert balance to a number and round it to the specified number of decimal places
    // const formatted = Number(balance).toFixed(toFixedNumber);
    // // Check if the formatted balance ends with '.000...' where the number of zeros equals toFixedNumber
    // if (formatted.endsWith(`.${'0'.repeat(toFixedNumber)}`)) {
    //     // If it does, remove those zeros and return the formatted balance without them
    //     return formatted.slice(0, - toFixedNumber + 1);
    // } else {
    //     // If not, return the formatted balance as is
    //     return formatted;
    // }
    const formatted = Math.floor(Number(balance) * 100) / 100;
    return formatted;

}

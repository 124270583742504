import './App.css';
import Routing from "./routing/Routing";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/css/style.css"
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
    <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Routing />
      </SkeletonTheme>
    </>
  );
}

export default App;

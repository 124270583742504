import { ColorRing } from "react-loader-spinner";

const Loader = ({ loaderType,height ,width}) => {
    if (loaderType == "COLOR_RING") {
        return (<ColorRing
            visible={true}
            height={height}
            width={width}
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#202020', '#444', '#202020', '#444', '#202020']}
        />)
    }
};

export default Loader;

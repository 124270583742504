import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";
import Swal from "sweetalert2";
import swal from "sweetalert";


const RPCURL = ({ maskAddress, copyTokenAddress, copied, convertUtcToLocal, showAddRpcModal, setShowAddRpcModal }) => {
    const [rpcList, setRPCList] = useState([])
    const [rpcStatus, setRPCStatus] = useState(true)
    const rpcRef = useRef(null);
    const [error, setError] = useState({ rpcUrl: "" });
    const [showLoader, setShowLoader] = useState(false)
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getRPCUrl(nextPage, "TOP")
    }, [])

    const getRPCUrl = async (page, callType) => {
        try {
            const response = await botApis.getRPCUrlLogs(page);
            if (response.status === 200) {
                let responseData = response.data.data;
                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...rpcList?.rpcs];
                    latestTokensTemp.push(...responseData?.rpcs);
                    responseData.tokens = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setRPCList(responseData)
                setRPCStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollRpc = async () => {
        if (rpcRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = rpcRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(rpcList.totalPages);
                if (page < totalPages) {
                    await getRPCUrl(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokentokensObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        tokentokensObserverTop.current = new IntersectionObserver(handleTokentokensObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (rpcList?.tokens?.length > 0) {
            tokentokensObserverTop.current.observe(document.querySelector('.latest-tokens-tokens-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, rpcList, rpcList?.rpcs]);

    const handleTokentokensObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallObserverTopRef.current) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getRPCUrl(0, "TOP");
        }
    };

    /* remove rpc */

    const removeRPC = (id) => {
        Swal.fire({
            title: 'Are you sure you want to remove this RPC Url?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                stopRPcURL(id)
            }
        });
    };

    /* remove */
    async function stopRPcURL(id) {
        try {
            const response = await botApis.removeRPCLogs(id);
            if (response.status === 200) {
                swal("Success", "Add RPCUrl removed successfully", "success").then(() => {
                })
                getRPCUrl(nextPage, "TOP")
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* Add Rpc Url */
    const [rpcUrl, setRpcUrl] = useState("");

    const handleAddRpcUrl = (e) => {
        e.preventDefault()
        let errorTemp = { ...error }, errorStatus = false;
        if (rpcUrl == "") {
            errorTemp.rpcUrl = "rpcUrl is required";
            errorStatus = true;
        }
        else {
            AddRPCUrl()
            errorStatus = false;
        }
    };

    async function AddRPCUrl() {
        const params = {
            rpcUrl: rpcUrl
        };
        try {
            setShowLoader(true);
            const response = await botApis.addRpcLogs(params);
            if (response.status === 200) {
                setShowLoader(false);
                setShowAddRpcModal(false)
                getRPCUrl(nextPage, "TOP")
                swal("Success", "Add RPCUrl successfully", "success").then(() => {
                })
            }
        } catch (error) {
            setShowLoader(false);
            exceptionHandling(error);
        }
    }


    return (<>
        <div className="transaction-tokens transaction-logs transaction-body token-mis-area rpc-area" ref={rpcRef} onScroll={onScrollRpc}>
            {/* <Button variant="primary" className="add-rpc-btn" onClick={(e) =>setShowAddRpcModal(true)}>
        Add RPC
      </Button> */}
            <Table>
                <thead>
                    <tr>
                        <th>URL</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <div className="latest-tokens-tokens-top"></div>
                    {
                        rpcStatus == true
                            ? (<tr>
                                <td className="token-front "><Skeleton height={15} width={120} count={20} /></td>
                                <td className="token-front"><Skeleton height={15} width={50} count={20} /></td>
                            </tr>) :
                            rpcList.rpcs?.length > 0 ?
                                rpcList?.rpcs?.map((message, index) => {
                                    return (
                                        <tr className={message.is_latest ? "latest_token" : ""}>
                                            <td className="token-front">{maskAddress(message.url, 15)}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-${index}`}>{copied[`RPCURL${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                >
                                                    <i class="far fa-copy" onClick={() => copyTokenAddress(message.url, `RPCURL${index}`)}
                                                    ></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td>
                                                <i onClick={() => removeRPC(message._id)} class="fa fa-trash remove-rpc-btn" aria-hidden="true"></i>

                                            </td>
                                        </tr>)
                                }) :
                                <>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="no-transaction-area">
                                                <img src={require("../assets/images/no-data.png")} alt="img" />
                                                <p>No RPC URL available.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                    }
                </tbody>
            </Table>
        </div>


        <Modal show={showAddRpcModal} onHide={(e) => setShowAddRpcModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add RPC URL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="rpcUrlInput">
                    <Form.Label className="text-light">RPC URL</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter RPC URL"
                        value={rpcUrl}
                        onChange={(e) => setRpcUrl(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === ' ' && e.target.value.length === 0) {
                                e.preventDefault();
                            }
                        }
                        }
                    />
                    {error.rpcUrl && !rpcUrl && <p className="error">{error.rpcUrl}</p>}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="add-main-btn close-main-btn" onClick={(e) => setShowAddRpcModal(false)}>
                    Close
                </Button>
                <Button variant="primary" className="add-main-btn" onClick={handleAddRpcUrl}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}
export default RPCURL;
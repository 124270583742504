import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { exceptionHandling, formatBalance } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";
import { SOL_ADDRESS } from "../config/config";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import { connectionWeb3 } from "../config/solanaWeb3";

const ManualSell = ({ showModal, closeModal, adminData, getTransactions, manualSellData, setManualSellData }) => {
    const [showLoader, setShowLoader] = useState(false);
    const { getTokenBalance, accountInfo } = connectionWeb3();
    const tokenBalanceRef = useRef();
    useEffect(() => {
        if (manualSellData?.sellBy && manualSellData?.sellBy == "HOME") {
            checkTokenAddressAndGetBalance(manualSellData.tokenAddress)
        } else {
            setManualSellData({ amountPercentage: 0, tokenAddress: "", tokenAmount: "", amount: "", errors: {} });
        }
    }, [])


    function handleInputChange(event) {
        const { name, value } = event.target;
        if (name == "amountPercentage") {
            if (tokenBalanceRef.current) {
                let amount = (tokenBalanceRef.current + tokenBalanceRef.current * Number(value) / 100);
                amount = amount - tokenBalanceRef.current
                setManualSellData({ ...manualSellData, amount: formatBalance(amount), amountPercentage: value });
            } else if (tokenBalanceRef.current == undefined) {
                setManualSellData({
                    ...manualSellData, amountPercentage: tokenBalanceRef.current ? value : 0,
                    errors: { tokenAddress: manualSellData?.tokenAddress ? "Invalid token address" : "Token address is required" }
                });
            }
        } else {
            setManualSellData({ ...manualSellData, [name]: value, errors: {} });
        }
    }

    async function validateForm() {
        let error = { tokenAddress: "", amount: "" }, status = false;
        if (!manualSellData?.tokenAddress) {
            error.tokenAddress = "Token address is required";
            status = true;
        } else {
            const tokenAccountInfo = await accountInfo(manualSellData?.tokenAddress)
            if (!tokenAccountInfo) {
                error.tokenAddress = "Invalid token address";
                status = true;
            }
        }
        if (!manualSellData?.amount || (Number(manualSellData.amount) <= 0)) {
            error.amount = "Amount is required";
            status = true;
        }
        return { status, error };
    }

    async function checkTokenAddressAndGetBalance(tokenAddress) {
        const error = { tokenAddress: "", };
        let tokenBalance
        const tokenAccountInfo = await accountInfo(tokenAddress)
        if (!tokenAccountInfo) {
            error.tokenAddress = "Invalid token address";
            tokenBalanceRef.current = "";
        } else {
            tokenBalance = await getTokenBalance(adminData["wallet_address"], tokenAddress);
            tokenBalanceRef.current = tokenBalance;
        }
        setManualSellData({ ...manualSellData, tokenAmount: tokenBalance, errors: error });
    }

    async function sellToken() {
        const errors = await validateForm();
        if (errors.status) {
            setManualSellData({ ...manualSellData, errors: errors.error });
            return;
        }
        const params = {
            fromToken: manualSellData.tokenAddress,
            toToken: SOL_ADDRESS,
            // userId: adminData["_id"],
            fromAmount: manualSellData.amount,
            type: "MANUAL SELL"
        };
        try {
            setShowLoader(true);
            // Example API call using fetch
            const response = await botApis.swapToken(params);
            if (response.status === 200) {
                // await botApis.startInProcess();
                // getTransactions();
                setShowLoader(false);
                swal("Success", "Manual token sell successfully", "success").then(() => {
                    closeModal(false);
                })
            }
        } catch (error) {
            setShowLoader(false);
            exceptionHandling(error);
        }
    }

    return (
        <Modal show={showModal} onHide={() => closeModal(false)} centered className="manual-sell-popup">
            <Modal.Header >
                <Modal.Title>Manual Sell</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Token Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Token Address" name="tokenAddress" value={manualSellData.tokenAddress} onChange={handleInputChange} onBlur={(e) => checkTokenAddressAndGetBalance(e.target.value)} />
                        {manualSellData?.errors && manualSellData?.errors.tokenAddress && <p className="error">{manualSellData?.errors.tokenAddress}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Amount {tokenBalanceRef.current && manualSellData.tokenAddress && !manualSellData?.errors?.tokenAddress && `${manualSellData.amount} (${manualSellData.amountPercentage}%)`}</Form.Label>
                        <Form.Control className="amount-range" type="range" min={0} max={100} placeholder="Enter Amount" name="amountPercentage" value={manualSellData.amountPercentage} onChange={handleInputChange} />
                        {manualSellData?.errors && manualSellData?.errors.amount && <p className="error">{manualSellData?.errors.amount}</p>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={sellToken} disabled={showLoader}>
                    {showLoader ? <Loader loaderType={"COLOR_RING"} width={25} height={25} /> : "Sell"}
                </Button>
                <Button variant="secondary" onClick={() => closeModal(false)} disabled={showLoader}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManualSell;
